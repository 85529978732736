import styled from '@emotion/styled'

import { sectionTitle, sectionSubtitle, mediaMax } from '@/styles/mixins'

import { GridContainer as GridContainerBase } from '@/components/Grid'
import { LinkWrapper as LinkWrapperBase } from '@/components/Link/style'
import { ButtonWrapper as Button } from '@/components/Button/style'
import WysiwygBase from '@/components/Wysiwyg'

export const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const LogoCercle = styled.img`
  position: absolute;
  top: 0;
  right: 10rem;
  width: 23rem;
  height: 23rem;
  object-fit: contain;
  z-index: 2;

  ${mediaMax.xs}{
    width: 13.2rem;
    height: 13.2rem;
    top: -6.5rem;
    right: 3rem;
  }
`

export const SubContainer = styled.div`
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  display: none;

  &.active {
    display: flex;
  }

  ${mediaMax.xs}{
      // padding: ${p => p.theme.paddingX.m};
    box-sizing: border-box;
    width: 100%;
  }
`

export const GridContainer = styled(GridContainerBase)`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  ${mediaMax.xs}{
    padding: ${p => p.theme.paddingX.m};
    padding-top: 8rem;
    box-sizing: border-box;
    width: 100VW;
  }
`

export const TopContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TopWrapper = styled.div`
  width: auto;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

export const Subtitle = styled.span`
  ${sectionSubtitle()};
  margin-bottom: 3rem;

  ${mediaMax.xs}{
    letter-spacing: 0.2rem;
  }
`

export const Title = styled.h3`
  ${sectionTitle()};
  font-size: 4.5rem;
  line-height: 5.5rem;
  color: ${({ theme }) => theme.colors.brown};
  margin-bottom: 3rem;

  ${mediaMax.xs}{
    font-size: 4.5rem;
    line-height: 5rem;
  }
`

export const Text = styled(WysiwygBase)`
  font-size: 1.4rem;
  line-height: 2.4rem;
  letter-spacing: .1rem;
  color: ${({ theme }) => theme.colors.brown};
  text-align: left;
  max-width: 80rem;

  ${mediaMax.xs}{
    text-align: left;
  }
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 3.2rem;

  ${mediaMax.xs}{
    width: 100%;
    overflow: hidden;
    flex-wrap: wrap;
    gap: 1.6rem;
  }
`

export const Item = styled.div`
  margin-bottom: 1.8rem;
  width: calc((100% - 3.6rem) / 3);
  padding: 4rem 4.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.lightGrey4};

  ${mediaMax.xs}{
    width: calc(50% - .8rem);
    // padding: 2.4rem 5rem;
    padding: 2rem;
    margin-bottom: 0;
  }
`

export const IconWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${mediaMax.xs}{
    width: 75px;
    height: 75px;
  }
`

export const ItemTitle = styled.span`
  font-size: 2.2rem;
  line-height: 2.7rem;
  letter-spacing: .1rem;
  color: ${({ theme }) => theme.colors.green};
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.serif};

  ${mediaMax.xs}{
    font-size: 1.4rem;
    line-height: 1.4rem;
    margin-bottom: .5rem;
  }
`

export const ItemText = styled(WysiwygBase)`
  margin-top: 1rem;
  font-size: 1.4rem;
  line-height: 2.2rem;
  letter-spacing: .1rem;
  color: ${({ theme }) => theme.colors.greyText};
  text-align: center;

  p {
    text-align: center;
    ${mediaMax.xs}{
      font-size: .9rem;
      line-height: 1rem;
    }
  }
`

export const BottomContainer = styled.div`
  margin-top: 2.2rem;
  align-self: flex-start;
  display: flex;
  align-items: center;
  justify-content: flex-start;

  ${mediaMax.xs} {
    margin-top: 3rem;
    justify-content: space-between;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    gap: 2rem;
    &.many {
      ${Button}{
        padding: 2rem 2.4rem;
      }
    }
  }

  ${LinkWrapperBase} {
    margin-left: 5rem;
    ${mediaMax.xs}{
      margin-left: 0;
    }
  }
`

export const Image = styled.img`
  width: 100%;
  object-fit: contain;
`

export const NavContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 3.2rem;
  gap: 2.4rem;


  ${mediaMax.xs}{
    flex-direction: column;
  }

`

export const NavItem = styled.div`
  font-family: ${({ theme }) => theme.fonts.sansSerif};
  font-size: 1.2rem;
  line-height: 1.5rem;
  letter-spacing: .14rem;
  padding: 3.1rem 7.4rem 3.1rem 6.8rem;
  position: relative;
  text-transform: uppercase;

  background-color: ${({ theme }) => theme.colors.lightGrey4};
  color: ${({ theme }) => theme.colors.brown};

  transition: all ease-out .3s;

  cursor: pointer;


  ${mediaMax.xs}{
    width: 100%;
  }


  &.active {
    background-color: #6C8255;
    color: ${({ theme }) => theme.colors.white};

    &:before {
      border: 1px solid ${({ theme }) => theme.colors.white};
    }

    &:after {
      opacity: 1;
      background: ${({ theme }) => theme.colors.white};
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 2.4rem;
    transform: translateY(-50%);
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.brown};
    transition: all ease-out .3s;
  }

  &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 3rem;
    transform: translateY(-50%);
    width: .8rem;
    height: .8rem;
    border-radius: 50%;
    opacity: 0;
    background: ${({ theme }) => theme.colors.brown};
    transition: all ease-out .3s;
  }
`
